import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import DiffText from "../DiffText";
import { useTranslations } from "../queries/useTranslations";
import { useVersions } from "../queries/useVersions";
import { type IDispatch, type IState, defaultContentItemSelector, selectDiffVersion } from "../state";
import type { ILanguage } from "../types";

interface IProps {
    version: string;
    id: string;
    lang: ILanguage;
}

function DefaultContentDiff({ id, lang, version }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const diffVersion = useSelector((state: IState) => state.diff.diffVersion);
    const { data: versions = [] } = useVersions();
    const { data: translations } = useTranslations(version);
    const { data: diffTranslations } = useTranslations(diffVersion);
    const text = useSelector((state: IState) => defaultContentItemSelector(state, version, id, translations!)[lang]);
    const diffText = useSelector((state: IState) =>
        diffVersion && diffTranslations
            ? defaultContentItemSelector(state, diffVersion, id, diffTranslations!)[lang]
            : null,
    );

    const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        dispatch(selectDiffVersion(value));
    };

    return (
        <>
            <select onChange={onChange} value={diffVersion}>
                <option value="" key="">
                    Select version
                </option>
                {versions
                    .filter((v) => v !== version)
                    .map((v) => (
                        <option value={v} key={v}>
                            {v}
                        </option>
                    ))}
            </select>
            <div>
                {!!diffText && (
                    <DiffText newText={text} oldText={diffText} oldVersion={diffVersion} newVersion={version} />
                )}
            </div>
        </>
    );
}

export default React.memo(DefaultContentDiff);
