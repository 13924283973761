import { useQuery } from "@tanstack/react-query";
import { getVersions } from "../api";
import { withAuth } from "../state/withAuth";

function authGetVersions() {
    return withAuth(() => getVersions());
}

export function useVersions() {
    return useQuery({
        queryKey: ["versions"],
        queryFn: authGetVersions,
        placeholderData: [],
        staleTime: Number.POSITIVE_INFINITY,
    });
}
