import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import * as Modal from "react-modal";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import GlobalStyle from "./GlobalStyle";
import { queryClient } from "./queries/queryClient";
import { initSentry } from "./sentry";
import { store } from "./state";

// init function
function init() {
    initSentry();
    try {
        Modal.setAppElement("#app");
        const container = document.getElementById("app");
        if (!container) {
            throw new Error("Failed to find the root element");
        }
        const root = createRoot(container);

        root.render(
            <BrowserRouter>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                            <App />
                            <GlobalStyle />
                        </Sentry.ErrorBoundary>
                    </QueryClientProvider>
                </Provider>
            </BrowserRouter>,
        );
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
}

init();
