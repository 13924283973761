import styled from "styled-components";

export const ContentRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(290px, 800px);
    grid-auto-flow: column;
    gap: 0.5rem;
`;

export const ContentBox = styled.div`
    display: grid;
    grid-template-rows: auto minmax(min-content, 1fr);
`;

interface IContentBorder {
    $pad: boolean;
}

export const ContentBorder = styled.div<IContentBorder>`
    border: 1px solid #8f8f9d;
    margin-top: 3px;
    z-index: 1;
    padding: ${({ $pad }) => ($pad ? "4px" : "0")};
    display: flex;
    flex-direction: column;
`;

export const Item = styled.div`
    margin-bottom: 15px;
`;

export const ContentError = styled.div`
    background-color: red;
    color: white;
    padding: 5px;
    margin: -3px 0 6px 0;
`;

export const PreviewBox = styled.div`
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
`;

export const IDHeading = styled.h4`
    font-weight: normal;
    margin-bottom: 5px;
`;

export const FormattingBox = styled.div`
    padding: 10px;
    border: 2px solid #cacaca;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0.5rem;
`;

export const FormattingBody = styled.div`
    max-width: 100ch;
`;

export const FormattingHeading = styled.h3`
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const TextEx = styled.span`
    display: inline-block;
    padding: 1px 2px;
    border: 1px solid darkgrey;
    background-color: #f6f6f6;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
`;

export const ButtonRow = styled.div`
    display: flex;
    gap: 2px;
`;

interface ITabButton {
    $open: boolean;
}

export const TabButton = styled.button<ITabButton>`
    padding: 5px;
    padding-bottom: 9px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: white;
    border: 1px solid #8f8f9d;
    border-bottom: none;
    margin-bottom: -4px;
    z-index: ${({ $open }) => ($open ? 2 : 0)};
`;
