import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
    authToken: string | null;
    showLoginModal: boolean;
}

const initialState: AuthState = {
    authToken: null,
    showLoginModal: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<string | null>) => {
            state.authToken = action.payload;
        },
        setShowLoginModal: (state, action: PayloadAction<boolean>) => {
            state.showLoginModal = action.payload;
        },
    },
});

export const { setAuthToken, setShowLoginModal } = authSlice.actions;
export default authSlice.reducer;

export const isAuthenticated = (state: { auth: AuthState }) => state.auth.authToken !== null;
