import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import LangHeading from "../LangHeading";
import type { IPageParams } from "../Main";
import ModuleSelector from "../ModuleSelector";
import PageNav from "../PageNav";
import { useTranslations } from "../queries/useTranslations";
import { type IState, docsPageSelector } from "../state";
import { getInt } from "../utils";
import Counts from "./Counts";
import DocFormatting from "./DocFormatting";
import DocRow from "./DocRow";

function Docs() {
    const { version, page } = useParams<IPageParams>();
    if (!version) {
        throw new Error("No version specified");
    }
    const { data: translations } = useTranslations(version);

    const pageNr = getInt(page) ?? 1;
    const { ids, pageCount } = useSelector((state: IState) => docsPageSelector(state, version, translations!, pageNr));

    return (
        <>
            <ModuleSelector content={"docs"} version={version} />
            <DocFormatting />
            <Counts version={version} content="docs" />
            <PageNav page={pageNr} maxPage={pageCount} version={version} content="docs" />
            <LangHeading />
            <div>
                {ids.map((id) => (
                    <DocRow key={id} id={id} version={version} />
                ))}
            </div>
            <PageNav page={pageNr} maxPage={pageCount} version={version} content="docs" />
        </>
    );
}

export default React.memo(Docs);
