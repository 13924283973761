import * as React from "react";

import PlusMinus from "../PlusMinus";
import { FormattingBox, FormattingHeading, TextEx, FormattingBody } from "../stylings";

function DocFormatting() {
    const [open, setOpen] = React.useState(false);
    const toggle = () => {
        setOpen((oldOpen) => !oldOpen);
    };
    return (
        <FormattingBox onClick={toggle}>
            <FormattingHeading>
                <PlusMinus open={open} />
                Formatting info
            </FormattingHeading>
            {open && (
                <FormattingBody>
                    <p>
                        Doc formatting uses a system called{" "}
                        <a href="https://commonmark.org/" target="_blank" rel="noreferrer">
                            Commonmark
                        </a>{" "}
                        to provide rich text formatting.
                    </p>
                    <h4>Formatting options</h4>
                    <p>
                        Leaving an empty line between two blocks of text splits the text up into two paragraphs when
                        displayed. Two empty spaces followed by a new new line adds a new line when displayed.
                    </p>
                    <p>
                        You can surround a piece of text with a single astrisk <TextEx>*</TextEx> to format the text as
                        italic. For example <TextEx>*This is italic*</TextEx> would display as{" "}
                        <TextEx>
                            <em>This is italic</em>
                        </TextEx>
                        .
                    </p>
                    <p>
                        You can get bold text by surround it with double astrisk <TextEx>**</TextEx>. For example{" "}
                        <TextEx>**This is bold**</TextEx> would display as{" "}
                        <TextEx>
                            <strong>This is bold</strong>
                        </TextEx>
                        .
                    </p>
                    <p>
                        You can do various sized heading by preceeding the row with a number of hashes{" "}
                        <TextEx>## </TextEx>. The more hashes, the smaller the heading. So{" "}
                        <TextEx>#### Small heading</TextEx> would display as:
                    </p>
                    <h4>Small heading</h4>
                    <p>
                        Links are done by first surrounding the link text in square bracket <TextEx>[ ]</TextEx>{" "}
                        immediately followed by the link surrounded in parentheses <TextEx>( )</TextEx>. A link to
                        google would be done like this <TextEx>[Google](https://www.google.com)</TextEx> and would
                        display as{" "}
                        <TextEx>
                            <a href="https://www.google.com">Google</a>
                        </TextEx>
                    </p>
                    <p>
                        Images are done similarly to links. The image is done by surrounding the alt text for the image
                        with square brackets preceeded by an exclamation mark, <TextEx>[ ]</TextEx>, immediately
                        followed by the link to the image surrounded in parentheses <TextEx>( )</TextEx>. For example{" "}
                        <TextEx>![TalentMiles logo](/static/TalentMiles-logo-liggande.png)</TextEx>, would display this
                        image: <br />
                        <img alt="TalentMiles logo" src="/static/TalentMiles-logo-liggande.png" width="320" />
                    </p>
                    <p>
                        You can make lists by preceeding each line with a list item with an astrisk <TextEx>* </TextEx>.
                        You can make sublists by adding two spaces before the asterisk. For eaxample: <br />
                        <TextEx>
                            * Item 1<br />* Item 2<br />
                            &nbsp;&nbsp;* Item 2a
                            <br />
                            &nbsp;&nbsp;* Item 2b
                            <br />
                        </TextEx>
                    </p>
                    <ul>
                        <li>Item 1</li>
                        <li>
                            Item 2
                            <ul>
                                <li>Item 2a</li>
                                <li>Item 2b</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        Numbered lists work the same way, except use numbers followed by a dot instead of asterisk:
                        <br />
                        <TextEx>
                            1. Item 1<br />
                            1. Item 2<br />
                        </TextEx>
                    </p>
                    <ol>
                        <li>Item 1</li>
                        <li>Item 2</li>
                    </ol>
                    <p>
                        You can surron a piece of text with backtick <TextEx>`</TextEx> to give it a code like look.
                        Example <TextEx>`looks like code`</TextEx> displays as{" "}
                        <TextEx>
                            <code>looks like code</code>
                        </TextEx>
                        .
                    </p>
                    <p>You can also include raw html.</p>
                    <p>You can click on the Preview button under a translation to see how formatting looks.</p>
                </FormattingBody>
            )}
        </FormattingBox>
    );
}

export default React.memo(DocFormatting);
