import * as React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

interface IProps {
    oldText: string;
    newText: string;
    oldVersion: string;
    newVersion: string;
}

function DiffText({ newText, oldText, oldVersion, newVersion }: IProps) {
    return (
        <ReactDiffViewer
            oldValue={oldText}
            newValue={newText}
            leftTitle={oldVersion}
            rightTitle={newVersion}
            splitView={true}
            hideLineNumbers
            compareMethod={DiffMethod.WORDS}
            showDiffOnly={false}
            styles={{
                diffContainer: {
                    pre: {
                        lineHeight: "17px",
                    },
                },
            }}
        />
    );
}

export default React.memo(DiffText);
