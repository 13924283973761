import { ButtonRow, TabButton } from "../stylings";

export type ITab = "editor" | "preview" | "diff";

interface IProps {
    tab: ITab;
    setTab: (tab: ITab) => void;
    onReset: VoidFunction;
    openAI: VoidFunction;
    openHistory: VoidFunction;
    showAI: boolean;
}

function EditorButtons({ tab, onReset, openAI, openHistory, showAI, setTab }: IProps) {
    return (
        <ButtonRow>
            <TabButton $open={tab === "editor"} type="button" onClick={() => setTab("editor")}>
                Editor
            </TabButton>
            <TabButton $open={tab === "preview"} type="button" onClick={() => setTab("preview")}>
                Preview
            </TabButton>
            <TabButton $open={tab === "diff"} type="button" onClick={() => setTab("diff")}>
                Changes
            </TabButton>
            <button type="button" onClick={onReset}>
                Reset
            </button>
            <button type="button" onClick={openHistory}>
                History
            </button>
            {showAI && (
                <button type="button" onClick={openAI}>
                    AI Assist
                </button>
            )}
        </ButtonRow>
    );
}

export default EditorButtons;
