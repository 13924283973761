import type * as React from "react";
import * as Modal from "react-modal";
import styled from "styled-components";

if (Modal.defaultStyles?.overlay) {
    Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.25)";
    Modal.defaultStyles.overlay.zIndex = 3;
}

const StyledModal = styled(Modal)`
    position: absolute;
    top: 4vw;
    left: 4vw;
    right: 4vw;
    max-height: calc(100vh - 8vw);
    overflow: auto;
    border-radius: 3px;
    padding: 2.2rem 1rem 1rem 1rem;
    border: solid 1px #666;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    background: #fff;
    z-index: 4;
`;

const CloseCross = styled.span`
    color: #aaa;
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    position: absolute;
    top: 0.625rem;
    right: 1.375rem;
    text-decoration: none;
    content: "×";
`;

const Wrapper = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 2rem;
`;

export default ({ children, ...props }: React.PropsWithChildren<Modal.Props>) => {
    return (
        <StyledModal {...props}>
            <Wrapper>{children}</Wrapper>
            <CloseCross onClick={props.onRequestClose}>×</CloseCross>
        </StyledModal>
    );
};
