import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import styled from "styled-components";

import type { IParams } from "./App";
import DisplayError from "./DisplayError";
import LangSelector from "./LangSelector";
import NavBar from "./NavBar";
import SaveButton from "./SaveButton";
import DefaultContent from "./editors/DefaultContent";
import Docs from "./editors/Docs";
import Texts from "./editors/Texts";
import { useTranslations } from "./queries/useTranslations";
import Search from "./search/Search";
import type { IDispatch } from "./state";
import { Row } from "./stylings";

export type IPageParams = IParams | "page";

const SaveRow = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
    padding: 5px 15px;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    gap: 1rem;
`;

function Main() {
    const dispatch = useDispatch<IDispatch>();
    const { version } = useParams<IParams>();
    if (!version) {
        throw new Error("Version is not defined");
    }

    const { data: translations, isPending, isError } = useTranslations(version);

    if (isPending) {
        return <CircleSpinner size={48} color="#666666" />;
    }
    if (isError) {
        return <DisplayError />;
    }
    return (
        <>
            <Row>
                <Search />
                <LangSelector />
            </Row>
            <NavBar version={version} />
            <Routes>
                <Route path={"texts/:page"} element={<Texts />} />
                <Route path={"texts"} element={<Navigate to={`/${version}/texts/1`} />} />
                <Route path={"docs/:page"} element={<Docs />} />
                <Route path={"docs"} element={<Navigate to={`/${version}/docs/1`} />} />
                <Route path={"default-content/:page"} element={<DefaultContent />} />
                <Route path={"default-content"} element={<Navigate to={`/${version}/default-content/1`} />} />
                <Route path={"/"} element={<Navigate to={`/${version}/texts/1`} />} />
            </Routes>
            <SaveRow>
                <SaveButton />
            </SaveRow>
        </>
    );
}

export default Main;
