import { useQuery } from "@tanstack/react-query";
import { type IHistoryArgs, getHistory } from "../api";
import { withAuth } from "../state/withAuth";

function authGetHistory(params: IHistoryArgs) {
    return withAuth(() => getHistory(params));
}

export function useHistory(params: IHistoryArgs) {
    return useQuery({
        queryKey: ["history", params.id, params.language, params.content],
        queryFn: () => authGetHistory(params),
        enabled: !!params.id && !!params.language && !!params.content,
        staleTime: 1000 * 60 * 10,
    });
}
