import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useTranslations } from "./queries/useTranslations";
import {
    type IDispatch,
    type IState,
    defaultContentModulesSelector,
    docsModulesSelector,
    textModulesSelector,
    toggleModule,
} from "./state";
import type { IContentType } from "./types";

const Wrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    label {
        margin-top: 0.5rem;
    }
`;

interface IProps {
    content: IContentType;
    version: string;
}

function ModuleSelector({ content, version }: IProps) {
    const { data: translations } = useTranslations(version);
    const dispatch = useDispatch<IDispatch>();
    const toggledModules = useSelector((state: IState) => state.modules);
    const modules = useSelector((state: IState) => {
        if (content === "texts") {
            return textModulesSelector(state, version, translations!);
        }
        if (content === "docs") {
            return docsModulesSelector(state, version, translations!);
        }
        if (content === "defaultContent") {
            return defaultContentModulesSelector(state, version, translations!);
        }
        return [];
    });

    const toggle = (event: React.FormEvent<HTMLInputElement>) => {
        dispatch(
            toggleModule({
                module: event.currentTarget.name,
                to: !event.currentTarget.checked,
            }),
        );
    };

    return (
        <fieldset>
            <legend>Select modules to show</legend>
            <Wrapper>
                {modules.map((mod) => (
                    <label key={mod}>
                        <input name={mod} type="checkbox" checked={!toggledModules[mod]} onChange={toggle} /> {mod}
                    </label>
                ))}
            </Wrapper>
        </fieldset>
    );
}

export default React.memo(ModuleSelector);
