import { type QueryClient, queryOptions, useQuery } from "@tanstack/react-query";
import { type IAiAssistArgs, getAiAssist } from "../api";
import { withAuth } from "../state/withAuth";
import { AI_LANGUAGES } from "../types";

const MAX_CONCURRENT_PREFETCH = 4;
const prefetchQueue: VoidFunction[] = [];
let activePrefetchCount = 0;

function processPrefetchQueue() {
    while (prefetchQueue.length > 0 && activePrefetchCount < MAX_CONCURRENT_PREFETCH) {
        const nextPrefetch = prefetchQueue.shift();
        if (nextPrefetch) {
            activePrefetchCount++;
            nextPrefetch();
        }
    }
}

function authGetAiAssist(params: IAiAssistArgs) {
    return withAuth(() => getAiAssist(params));
}

function aiQueryOptions(params: IAiAssistArgs) {
    const skip = !AI_LANGUAGES.includes(params.language);
    return queryOptions({
        queryKey: ["ai-assist", params.language, params.text, { version: params.version }],
        queryFn: () => authGetAiAssist(params),
        enabled: !!params.text && !skip,
        staleTime: Number.POSITIVE_INFINITY,
        gcTime: Number.POSITIVE_INFINITY,
    });
}

export function useAiAssist(params: IAiAssistArgs) {
    return useQuery(aiQueryOptions(params));
}

export function prefetchAiAssist(params: IAiAssistArgs, queryClient: QueryClient) {
    const queryKey = ["ai-assist", params.language, params.text];

    // If data is already in cache, don't prefetch
    if (queryClient.getQueryData(queryKey)) {
        return;
    }

    const prefetchFn = () => {
        queryClient.prefetchQuery(aiQueryOptions(params)).finally(() => {
            activePrefetchCount--;
            processPrefetchQueue();
        });
    };

    prefetchQueue.push(prefetchFn);
    processPrefetchQueue();
}
