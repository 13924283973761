import type * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import { useVersions } from "./queries/useVersions";
import { type IDispatch, type IState, initEditState } from "./state";

function VersionPicker() {
    const dispatch = useDispatch<IDispatch>();
    const navigate = useNavigate();
    const match = useMatch("/:version/*");
    const { data: versions = [], isPending } = useVersions();

    const changeVersion = (event: React.FormEvent<HTMLSelectElement>) => {
        const version = event.currentTarget.value;
        navigate(`/${version}`);
        if (version) {
            dispatch(initEditState(version));
        }
    };

    if (isPending) {
        return <CircleSpinner size={28} color="#666666" />;
    }

    return (
        <select onChange={changeVersion} value={match?.params.version}>
            <option value="" key="">
                Select version
            </option>
            {versions.reverse().map((v) => (
                <option value={v} key={v}>
                    {v}
                </option>
            ))}
        </select>
    );
}

export default VersionPicker;
