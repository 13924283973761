import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";
import Modal from "../Modal";
import { prefetchAiAssist } from "../queries/useAiAssist";
import { useTranslations } from "../queries/useTranslations";
import { type IDispatch, type IState, defaultContentItemSelector, editContent, resetEdit } from "../state";
import { ContentRow, IDHeading, Item } from "../stylings";
import { type ILanguage, LANGUAGES, REFERENCE_LANGUAGE } from "../types";
import AiAssist from "./AiAssist";
import DefaultContentItem from "./DefaultContentItem";
import History from "./History";

interface IProps {
    id: string;
    version: string;
}

function DefaultContentRow({ id, version }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const { data: translations } = useTranslations(version);
    const texts = useSelector((state: IState) => defaultContentItemSelector(state, version, id, translations!));
    const langs = useSelector((state: IState) => state.languages);
    const [aiModalContent, setAiModalContent] = React.useState<{
        text: string;
        lang: ILanguage;
    } | null>(null);
    const [historyModalContent, setHistoryModalContent] = React.useState<ILanguage | null>(null);
    const queryClient = useQueryClient();
    const diffVersion = useSelector((state: IState) => state.diff.diffVersion);

    const changeHandler = React.useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>, lang: ILanguage) => {
            dispatch(
                editContent({
                    version,
                    lang,
                    id,
                    content: "defaultContent",
                    value: event.currentTarget.value,
                }),
            );
        },
        [dispatch, version, id],
    );

    const resetHandler = React.useCallback(
        (lang: ILanguage) => {
            if (!window.confirm("This will throw away your changes reset it back to the latest saved version.")) {
                return;
            }
            dispatch(resetEdit({ version, lang, id, content: "defaultContent" }));
        },
        [dispatch, version, id],
    );

    const closeAi = React.useCallback(() => {
        setAiModalContent(null);
    }, []);

    const closeHistory = React.useCallback(() => {
        setHistoryModalContent(null);
    }, []);

    const setAiModal = React.useCallback(
        (lang: ILanguage) => {
            setAiModalContent({ lang, text: texts[REFERENCE_LANGUAGE] });
        },
        [texts],
    );

    const setHistoryModal = React.useCallback((lang: ILanguage) => {
        setHistoryModalContent(lang);
    }, []);

    React.useEffect(() => {
        // biome-ignore lint/complexity/noForEach: <explanation>
        LANGUAGES.filter((lang) => langs[lang]).forEach((lang) => {
            if (lang !== REFERENCE_LANGUAGE) {
                prefetchAiAssist(
                    {
                        language: lang,
                        text: texts[REFERENCE_LANGUAGE],
                        version,
                    },
                    queryClient,
                );
            }
        });
    }, [langs, texts, version, queryClient]);

    return (
        <Item>
            <IDHeading>ID: {id}</IDHeading>
            <ContentRow>
                {LANGUAGES.filter((lang) => langs[lang]).map((lang) => (
                    <DefaultContentItem
                        key={lang}
                        lang={lang}
                        text={texts[lang]}
                        resetHandler={resetHandler}
                        changeHandler={changeHandler}
                        setAiModal={setAiModal}
                        setHistoryModal={setHistoryModal}
                        id={id}
                        version={version}
                    />
                ))}
            </ContentRow>
            <Modal isOpen={!!aiModalContent} onRequestClose={closeAi} contentLabel="AI Assist">
                <AiAssist
                    close={closeAi}
                    version={version}
                    id={id}
                    text={aiModalContent?.text ?? ""}
                    lang={aiModalContent?.lang ?? "en"}
                    content="defaultContent"
                />
            </Modal>
            <Modal isOpen={!!historyModalContent} onRequestClose={closeHistory} contentLabel="Translation history">
                <History
                    close={closeHistory}
                    version={version}
                    id={id}
                    lang={historyModalContent ?? "en"}
                    content="defaultContent"
                />
            </Modal>
        </Item>
    );
}

export default React.memo(DefaultContentRow);
