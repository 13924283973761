import type * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { useVersions } from "../queries/useVersions";
import { type IDispatch, type IState, selectDiffVersion, updateVersionFilter } from "../state";

interface IProps {
    version: string;
}

const Select = styled.select`
    width: 100%;
`;

function ChangeFilter({ version }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const value = useSelector((state: IState) => state.filters[version]?.versionFilter ?? "");
    const { data: versions = [] } = useVersions();
    const filteredVersions = versions.filter((v) => v !== version);

    const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        dispatch(updateVersionFilter({ version, value }));
        dispatch(selectDiffVersion(value));
    };

    return (
        // biome-ignore lint/a11y/noLabelWithoutControl: Select is an input
        <label>
            Show only translations that have changed since version:
            <Select value={value} onChange={onChange}>
                <option value="">---</option>
                {filteredVersions.map((v) => (
                    <option key={v} value={v}>
                        {v}
                    </option>
                ))}
            </Select>
        </label>
    );
}

export default ChangeFilter;
