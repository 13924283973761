import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IPartialLangDict } from "../queries/useTranslations";
import type { IContentType, ILanguage } from "../types";

interface IEdits {
    data: {
        [P in IContentType]?: IPartialLangDict;
    };
}

interface ITranslationsState {
    [index: string]: IEdits;
}

const initialState: ITranslationsState = {};

function newVersionState(): IEdits {
    return {
        data: {},
    };
}

interface IEditPayload {
    version: string;
    content: IContentType;
    lang: ILanguage;
    id: string;
    value: string;
}

interface IResetPayload {
    version: string;
    content: IContentType;
    lang: ILanguage;
    id: string;
}

const editsSlice = createSlice({
    name: "edits",
    initialState,
    reducers: {
        initEditState(state, action: PayloadAction<string>) {
            const version = action.payload;
            if (!state[version]) {
                state[version] = newVersionState();
            }
        },
        editContent(state, action: PayloadAction<IEditPayload>) {
            const { version, content, lang, id, value } = action.payload;
            if (state[version] === undefined) {
                state[version] = newVersionState();
            }
            if (state[version].data[content] === undefined) {
                state[version].data[content] = {};
            }
            if (state[version].data[content][id] === undefined) {
                state[version].data[content][id] = {};
            }
            state[version].data[content][id][lang] = value;
        },
        resetEdit(state, action: PayloadAction<IResetPayload>) {
            const { version, content, lang, id } = action.payload;
            if (state[version] === undefined) {
                return;
            }
            if (state[version].data[content] === undefined) {
                return;
            }
            if (state[version].data[content][id] === undefined) {
                return;
            }
            delete state[version].data[content][id][lang];
            if (!Object.values(state[version].data[content][id]).some((v) => !!v)) {
                delete state[version].data[content][id];
            }
            if (!Object.values(state[version].data[content]).some((v) => !!v)) {
                delete state[version].data[content];
            }
        },
        resetAllEdits(state, action: PayloadAction<string>) {
            const version = action.payload;
            state[version].data = {};
        },
    },
});

export const { editContent, resetEdit, resetAllEdits, initEditState } = editsSlice.actions;
export default editsSlice.reducer;
