import * as React from "react";
import * as MessageFormat from "messageformat";
import { parse } from "messageformat-parser";

import type { ILanguage } from "../types";

interface IProps {
    text: string;
    lang: ILanguage;
}

function TextPreview({ text, lang }: IProps) {
    const [args, setArgs] = React.useState<{ [index: string]: string }>(() => {
        const tokens = parse(text);
        const out: { [index: string]: string } = {};
        for (const token of tokens) {
            if (typeof token === "string") {
                continue;
            }
            if (token.arg) {
                out[token.arg] = "";
            }
        }
        return out;
    });

    const translation = React.useMemo(() => {
        const mf = new MessageFormat(lang);
        return mf.compile({ id: text }) as any;
    }, [text, lang]);

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        setArgs((oldArgs) => ({
            ...oldArgs,
            [name]: value,
        }));
    };

    return (
        <>
            {Object.entries(args).map(([name, value]) => (
                <label key={name}>
                    {name}
                    <input name={name} value={value} onChange={onChange} />
                </label>
            ))}
            <div>{translation.id(args)}</div>
        </>
    );
}

export default React.memo(TextPreview);
