import * as Sentry from "@sentry/react";

export function initSentry() {
    if (window.__CONFIG__?.SENTRY_DSN) {
        Sentry.init({
            dsn: window.__CONFIG__.SENTRY_DSN,
            release: window.__CONFIG__.RELEASE,
        });
    }
}
