import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchTranslations } from "../queries/useTranslations";
import { type IContentType, type ILanguage, REFERENCE_LANGUAGE } from "../types";
import type { IState } from "./index";
import { getSearchString } from "./searchCache";

export const search = createAsyncThunk("filters/search", async (version: string, thunkAPI) => {
    let state = thunkAPI.getState() as IState;
    const { idFilter, searchFilter, versionFilter, showEmpty } = state.filters[version] ?? {
        idFilter: "",
        searchFilter: "",
        versionFilter: "",
        showEmpty: [],
    };

    const translations = await fetchTranslations(version);
    let compareTranslation = null;
    if (versionFilter) {
        compareTranslation = await fetchTranslations(versionFilter);
    }

    state = thunkAPI.getState() as IState;
    const edits = state.edits[version];
    const out: { [key in IContentType]: string[] } = {
        texts: [],
        docs: [],
        defaultContent: [],
    };
    const trimIdFilter = idFilter.trim();
    const trimSearchFilter = searchFilter.trim().toLowerCase();
    const langs = Object.entries(state.languages)
        .filter(([_lang, enabled]) => enabled)
        .map(([lang, _enabled]) => lang as ILanguage);
    for (const content of Object.keys(translations) as IContentType[]) {
        // eslint-disable-next-line prefer-const
        let { ids, dict } = translations[content];
        const contentEdits = edits.data[content] ?? {};
        if (trimIdFilter) {
            ids = ids.filter((id) => id.includes(trimIdFilter));
        }
        if (trimSearchFilter) {
            ids = ids.filter((id) =>
                getSearchString(content, id, dict[id], langs, contentEdits[id]).includes(trimSearchFilter),
            );
        }
        if (compareTranslation) {
            const compareDict = compareTranslation[content]?.dict ?? {};
            ids = ids.filter((id) => {
                if (!!dict[id] && !compareDict[id]) {
                    return true;
                }
                const val = dict[id][REFERENCE_LANGUAGE]?.trim();
                const ref = compareDict[id][REFERENCE_LANGUAGE]?.trim();
                return val !== ref;
            });
        }
        if (showEmpty.length > 0) {
            ids = ids.filter((id) =>
                Object.entries(contentEdits[id] ?? dict[id]).some(
                    ([lang, val]) => showEmpty.includes(lang as ILanguage) && !val,
                ),
            );
        }
        out[content] = ids;
    }
    return out;
});

interface IFilterVersionState {
    showEmpty: ILanguage[];
    isSearching: boolean;
    idFilter: string;
    searchFilter: string;
    versionFilter: string;
    searchResults?: {
        texts: string[];
        docs: string[];
        defaultContent: string[];
    };
}

interface IFIltersState {
    [index: string]: IFilterVersionState;
}

function newFilterState(): IFilterVersionState {
    return {
        showEmpty: [],
        isSearching: false,
        idFilter: "",
        searchFilter: "",
        versionFilter: "",
    };
}

const initialState: IFIltersState = {};

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        updateIdFilter(state, action: PayloadAction<{ version: string; value: string }>) {
            const { version, value } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].idFilter = value.toLowerCase();
        },
        updateSearchFilter(state, action: PayloadAction<{ version: string; value: string }>) {
            const { version, value } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].searchFilter = value;
        },
        updateVersionFilter(state, action: PayloadAction<{ version: string; value: string }>) {
            const { version, value } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].versionFilter = value;
        },
        clearSearch(state, action: PayloadAction<string>) {
            const version = action.payload;
            state[version] = newFilterState();
        },
        addShowEmpty(state, action: PayloadAction<{ version: string; lang: ILanguage }>) {
            const { lang, version } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].showEmpty.push(lang);
        },
        removeShowEmpty(state, action: PayloadAction<{ version: string; lang: ILanguage }>) {
            const { lang, version } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].showEmpty = state[version].showEmpty.filter((l) => l !== lang);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(search.pending, (state, action) => {
            const version = action.meta.arg;
            state[version].isSearching = true;
        });
        builder.addCase(search.rejected, (state, action) => {
            const version = action.meta.arg;
            state[version].isSearching = true;
        });
        builder.addCase(search.fulfilled, (state, action) => {
            const version = action.meta.arg;
            const payload = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].searchResults = payload;
            state[version].isSearching = false;
        });
    },
});

export const { updateIdFilter, updateSearchFilter, clearSearch, updateVersionFilter, addShowEmpty, removeShowEmpty } =
    filtersSlice.actions;
export default filtersSlice.reducer;
