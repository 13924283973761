import * as React from "react";
import { useDispatch } from "react-redux";
import { CircleSpinner } from "react-spinners-kit";
import styled from "styled-components";
import { useHistory } from "../queries/useHistory";
import { type IDispatch, editContent } from "../state";
import type { IContentType, ILanguage } from "../types";

interface IProps {
    close: VoidFunction;
    version: string;
    id: string;
    lang: ILanguage;
    content: IContentType;
}

function formatDate(isoDateString: string | null): string {
    console.log(isoDateString);
    if (!isoDateString) {
        return "";
    }
    try {
        const date = new Date(isoDateString);
        return new Intl.DateTimeFormat(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    } catch (error) {
        console.error("Error formatting date:", error);
        return "";
    }
}

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    font-size: 0.9rem;

    th, td {
        padding: 0.75rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #f2f2f2;
        font-weight: bold;
    }

    tbody tr:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

function History({ close, id, lang, content, version }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const { data, isError, isPending } = useHistory({ language: lang, id, content });

    const onUse = (text: string) => {
        close();
        dispatch(editContent({ version, lang, id, content, value: text }));
    };

    let main = null;
    if (isPending) {
        main = <CircleSpinner size={48} color="#666666" />;
    } else if (isError) {
        main = <p>Error</p>;
    } else if (data) {
        main = (
            <StyledTable>
                <thead>
                    <tr>
                        <th>Version</th>
                        <th>Date</th>
                        <th>Translation</th>
                        <th>Translator</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((datum) => (
                        <tr key={datum.id}>
                            <td>{datum.version}</td>
                            <td>{formatDate(datum.createdAt)}</td>
                            <td>{datum.content}</td>
                            <td>{datum.user}</td>
                            <td>
                                <button type="button" onClick={() => onUse(datum.content)}>
                                    Use
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        );
    }

    return (
        <>
            <h2>Translation history</h2>
            {main}
        </>
    );
}

export default React.memo(History);
