import * as React from "react";

import PlusMinus from "../PlusMinus";
import { FormattingBox, FormattingHeading, TextEx, FormattingBody } from "../stylings";

function TextFormatting() {
    const [open, setOpen] = React.useState(false);
    const toggle = () => {
        setOpen((oldOpen) => !oldOpen);
    };
    return (
        <FormattingBox onClick={toggle}>
            <FormattingHeading>
                <PlusMinus open={open} />
                Formatting info
            </FormattingHeading>
            {open && (
                <FormattingBody>
                    <p>
                        Text formatting uses a system called{" "}
                        <a href="https://messageformat.github.io/messageformat/" target="_blank" rel="noreferrer">
                            MessageFormat
                        </a>{" "}
                        to handle dynamically adjusting the translated text content based on input. MessageFormat is
                        used whenever there curly bracers {"{}"}. There are three main ways MessageFormat is used here:
                    </p>
                    <h4>Fill in value</h4>
                    <p>
                        The simples way message format is used is to fill in a value into the text. This is done by
                        having an argument inside curly braces. For example, if the text is{" "}
                        <TextEx>
                            The marbels are {"{"}color{"}"}.
                        </TextEx>{" "}
                        then the argument named <TextEx>color</TextEx> will be replaced by a value when the text is
                        displayed. If the value for the color argument was <TextEx>white</TextEx> then the text would
                        display as <TextEx>The marbels are white.</TextEx>
                    </p>
                    <h4>Select on of multiple translations</h4>
                    <p>
                        MessageFormat can also be used to select between multiple different translation options based on
                        the value of an argument. It looks like this{" "}
                        <TextEx>
                            {"{"}mediaType, select, audio{"{"}Äänite{"}"} video
                            {"{"}Video{"}"} other{"{"}Muu{"}}"}
                        </TextEx>
                        . It consists of three parts, separated by commas:
                    </p>
                    <ol>
                        <li>
                            The name of the argument, in this case <TextEx>mediaType</TextEx>.
                        </li>
                        <li>
                            Then there is the keyword <TextEx>select</TextEx> which indicated that here MessageFormat
                            should pick one of the options based on the argument.
                        </li>
                        <li>
                            Finally there is a list of options. Each item in the list consists of the value it
                            represents, followed by the translation inside curly braces. In the end of the list here is
                            the <TextEx>other</TextEx> option, which is what is used if nothing else in the list matches
                            the value.
                        </li>
                    </ol>
                    <p>
                        In this example, if the argument <TextEx>mediaType</TextEx> had a value of{" "}
                        <TextEx>audio</TextEx>, then the text would display as <TextEx>Äänite</TextEx>.
                    </p>
                    <h4>Select between multiple translation options based on a numerical value</h4>
                    <p>
                        The way a piece of text is phrased can depend on some numerical value. MessageFormat'a plural
                        mode is a variant of its select more specifically for selecting a plurlar form. It looks like
                        this{" "}
                        <TextEx>
                            Failed to load {"{"}count, plural, one{"{"}task{"}"} other{"{"}
                            tasks{"}}"}
                        </TextEx>
                        . It consists of three parts, separated by commas:
                    </p>
                    <ol>
                        <li>
                            The name of the argument, in this case <TextEx>count</TextEx>.
                        </li>
                        <li>
                            Then there is the keyword <TextEx>plural</TextEx> which indicated that here MessageFormat
                            should pick one of the options based on the numerical value of the argument.
                        </li>
                        <li>
                            Finally there is a list of options. Each item in the list consists of the plural value it
                            represents, followed by the translation inside curly braces. There are several different
                            possible plural forms:
                            <ul>
                                <li>
                                    <TextEx>zero</TextEx> - The translation to use for zero
                                </li>
                                <li>
                                    <TextEx>one</TextEx> - The translation to use for the singular case
                                </li>
                                <li>
                                    <TextEx>two</TextEx> - The translation to use for the ducal case
                                </li>
                                <li>
                                    <TextEx>few</TextEx> - The translation to use for the paucal case
                                </li>
                                <li>
                                    <TextEx>many</TextEx> - The translation to use for the many case
                                </li>
                                <li>
                                    <TextEx>other</TextEx> - The translation to use for the generic plural case
                                </li>
                                <li>
                                    <TextEx>=0</TextEx>, <TextEx>=1</TextEx>, <TextEx>=2</TextEx>
                                    ... - The translation to use for the specified number
                                </li>
                            </ul>
                            What numerical values maps to which of those options depends on the language. A detailed
                            list can be found{" "}
                            <a
                                href="https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_plural_rules.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </a>
                            . The option can also be of the format equal sign followed by a number (ex{" "}
                            <TextEx>{"=7{seven case}"}</TextEx>). In that case the translation is used if the numerical
                            value exacly equals the one specified.
                        </li>
                    </ol>
                    <p>
                        Clicking the preview button for any translation allows you to fill in values for the arguments
                        and preview what the result looks like.
                    </p>
                </FormattingBody>
            )}
        </FormattingBox>
    );
}

export default React.memo(TextFormatting);
