import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    html {
        margin: 0;
        padding: 0;
        font-family: 'Proxima Nova';
        width: 100%;
        height: 100%;
    }

    body {
        padding: 15px;
        width: 100%;
        height: 100%;
    }

    #app {
        width: 100%;
        height: 100%;
    }

    h1, h2, h3, h4, h5 {
        margin: 0;
    }

    input {
        width: 100%;
    }

    input[type="checkbox"] {
        width: auto;
    }

    label {
        display: block;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
    }

    fieldset {
        margin-bottom: 1rem;
        border-radius: 0.25rem;
    }

    legend {
        font-size: 1.15rem;
    }

    img {
        max-width: 100%;
    }

    textarea {
        font-size: 14px;
        line-height: 17px;
        padding: 4px;
        resize: none;
        font-family: monospace;
    }

    pre {
        font-size: 14px;
        white-space: pre-wrap;
    }
`;

export default GlobalStyle;
