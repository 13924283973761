import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useTranslations } from "../queries/useTranslations";
import { type IState, defaultContentCountsSelector, docCountsSelector, textCountsSelector } from "../state";
import type { IContentType } from "../types";

interface IProps {
    version: string;
    content: IContentType;
}

const Wrapper = styled.div`
    margin-bottom: 0.5rem;
`;

function Counts({ version, content }: IProps) {
    const { data: translations } = useTranslations(version);
    const [currentCount, totalCount] = useSelector((state: IState) =>
        content === "texts"
            ? textCountsSelector(state, version, translations!)
            : content === "docs"
              ? docCountsSelector(state, version, translations!)
              : content === "defaultContent"
                ? defaultContentCountsSelector(state, version, translations!)
                : [0, 0],
    );

    return (
        <Wrapper>
            {currentCount === totalCount
                ? `Showing ${currentCount} items`
                : `Showing ${currentCount} of ${totalCount} items`}
        </Wrapper>
    );
}

export default React.memo(Counts);
