import { setShowLoginModal, store } from "../state";
import { isAuthenticated } from "./auth";

export async function withAuth<T>(apiCall: () => Promise<T>): Promise<T> {
    try {
        return await apiCall();
    } catch (error: unknown) {
        if (error instanceof Error && error.message === "Unauthorized") {
            store.dispatch(setShowLoginModal(true));
            await new Promise<void>((resolve) => {
                const unsubscribe = store.subscribe(() => {
                    if (!store.getState().auth.showLoginModal) {
                        unsubscribe();
                        resolve();
                    }
                });
            });
            if (isAuthenticated(store.getState())) {
                return await apiCall();
            }
            throw new Error("Authentication failed");
        }
        throw error;
    }
}
