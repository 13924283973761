import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchTranslations } from "../queries/useTranslations";

export const selectDiffVersion = createAsyncThunk("diff/selectDiffVersion", async (version: string, thunkAPI) => {
    if (version) {
        await fetchTranslations(version);
    }
    thunkAPI.dispatch(diffSlice.actions.setDiffVersion({ version }));
});

type IModulesState = {
    diffVersion: string;
};

const initialState: IModulesState = {
    diffVersion: "",
};

const diffSlice = createSlice({
    name: "diff",
    initialState,
    reducers: {
        setDiffVersion(state, action: PayloadAction<{ version: string }>) {
            const { version } = action.payload;
            state.diffVersion = version;
        },
    },
});

export default diffSlice.reducer;
