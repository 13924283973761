import * as React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledNavLink = styled(NavLink)`
    border: 1px solid grey;
    padding: 2px 7px;
    border-radius: 4px;
    color: black;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: black;
    }
    &.active {
        font-weight: bold;
    }
`;

const Nav = styled.nav`
    display: flex;
    gap: 3px;
    margin-bottom: 0.5rem;
`;

const Span = styled.span`
    padding: 2px 7px;
    font-size: 1.2rem;
`;

interface IProps {
    page: number;
    maxPage: number;
    content: "texts" | "docs" | "default-content";
    version: string;
}

function range(start: number, end: number): number[] {
    const out = [];
    for (let i = start; i < end; i++) {
        out.push(i);
    }
    return out;
}

function PageNav({ page, maxPage }: IProps) {
    const firstPage = Math.max(1, page - 10);
    const lastPage = Math.min(maxPage, page + 10);
    const pageNumbers = range(firstPage, lastPage + 1);
    if (pageNumbers.length === 1) {
        return null;
    }
    return (
        <Nav>
            {firstPage > 1 && <Span>. . .</Span>}
            {pageNumbers.map((num) => (
                <StyledNavLink to={`../${num}`} key={num} relative="path">
                    {num}
                </StyledNavLink>
            ))}
            {lastPage < maxPage && <Span>. . .</Span>}
        </Nav>
    );
}

export default PageNav;
