import { configureStore } from "@reduxjs/toolkit";
import { load, save } from "redux-localstorage-simple";

import editsReducer from "./edits";
export {
    editContent,
    resetEdit,
    resetAllEdits,
    initEditState,
} from "./edits";

import languagesReducer from "./languages";
export { toggleLanguage } from "./languages";

import modulesReducer from "./modules";
export { toggleModule } from "./modules";

import diffReducer from "./diff";
export { selectDiffVersion } from "./diff";

import filtersReducer from "./filters";
export {
    updateIdFilter,
    updateSearchFilter,
    search,
    clearSearch,
    updateVersionFilter,
    addShowEmpty,
    removeShowEmpty,
} from "./filters";

import authReducer from "./auth";
export { setAuthToken, setShowLoginModal } from "./auth";

export const store = configureStore({
    reducer: {
        languages: languagesReducer,
        filters: filtersReducer,
        edits: editsReducer,
        modules: modulesReducer,
        diff: diffReducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            save({
                states: ["languages", "edits", "modules", "auth"],
                namespace: "translator",
            }),
        ),
    preloadedState: load({
        states: ["languages", "edits", "modules", "auth"],
        namespace: "translator",
    }),
});

export type IStore = typeof store;
export type IState = ReturnType<IStore["getState"]>;
export type IDispatch = IStore["dispatch"];

export {
    anyUnsavedChangesSelector,
    defaultContentCountsSelector,
    defaultContentItemSelector,
    defaultContentModulesSelector,
    defaultContentPageSelector,
    docCountsSelector,
    docsItemSelector,
    docsModulesSelector,
    docsPageSelector,
    hasSearchResults,
    textCountsSelector,
    textModulesSelector,
    textsItemSelector,
    textsPageSelector,
    unsavedChangesSelector,
} from "./selectors";
