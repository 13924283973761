import * as React from "react";
import { useDebounce } from "use-debounce";

import { ContentBorder, ContentBox, ContentError } from "../stylings";
import { AI_LANGUAGES, type ILanguage } from "../types";
import DefaultContentDiff from "./DefaultContentDiff";
import DefaultContentPreview from "./DefaultContentPreview";
import EditorButtons from "./EditorButtons";
import Textarea from "./Textarea";

interface IProps {
    text: string;
    lang: ILanguage;
    id: string;
    version: string;
    resetHandler: (lang: ILanguage) => void;
    changeHandler: (event: React.FormEvent<HTMLTextAreaElement>, lang: ILanguage) => void;
    setAiModal: (lang: ILanguage) => void;
    setHistoryModal: (lang: ILanguage) => void;
}

const OPEN_CLOSE = /{{|}}/gm;

function validate(value: string): string {
    if (!value) {
        return "";
    }
    let open = false;
    const matches = value.match(OPEN_CLOSE);
    if (!matches) {
        return "";
    }
    for (const match of matches) {
        if (open && match === "{{") {
            return "Found invalid nested opening tags {{";
        }
        if (!open && match === "}}") {
            return "Found invalid closing tag }}";
        }
        if (!open && match === "{{") {
            open = true;
        }
        if (open && match === "}}") {
            open = false;
        }
    }
    if (open) {
        return "Missing closing tag }}";
    }
    return "";
}

type ITab = "editor" | "preview" | "diff";

function DeafultContentItem({
    text,
    changeHandler,
    resetHandler,
    lang,
    setAiModal,
    version,
    id,
    setHistoryModal,
}: IProps) {
    const [debounceText] = useDebounce(text, 1000);
    const [tab, setTab] = React.useState<ITab>("editor");

    const error = React.useMemo(() => {
        return validate(debounceText);
    }, [debounceText]);

    let mainContent = null;
    if (tab === "editor") {
        mainContent = (
            <ContentBorder $pad={false}>
                <Textarea value={text} onChange={(e) => changeHandler(e, lang)} />
                {error && <ContentError>{error}</ContentError>}
            </ContentBorder>
        );
    } else if (tab === "preview") {
        mainContent = (
            <ContentBorder $pad={true}>
                <DefaultContentPreview text={text} />
            </ContentBorder>
        );
    } else if (tab === "diff") {
        mainContent = (
            <ContentBorder $pad={true}>
                <DefaultContentDiff version={version} lang={lang} id={id} />
            </ContentBorder>
        );
    }

    return (
        <ContentBox>
            <EditorButtons
                tab={tab}
                setTab={setTab}
                onReset={() => resetHandler(lang)}
                openAI={() => setAiModal(lang)}
                openHistory={() => setHistoryModal(lang)}
                showAI={AI_LANGUAGES.includes(lang)}
            />
            {mainContent}
        </ContentBox>
    );
}

export default React.memo(DeafultContentItem);
