import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircleSpinner } from "react-spinners-kit";
import styled from "styled-components";

import Nl2br from "../Nl2br";
import { useAiAssist } from "../queries/useAiAssist";
import { type IDispatch, type IState, editContent } from "../state";
import type { IContentType, ILanguage } from "../types";

interface IProps {
    close: VoidFunction;
    version: string;
    id: string;
    lang: ILanguage;
    content: IContentType;
    text: string;
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 1rem;
    gap: 1rem;
`;

const Trans = styled.div`
    padding: 4px;
    border: 1px solid rgb(218, 218, 218);
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: flex-start;
`;

function AiAssist({ close, version, id, lang, content, text }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const diffVersion = useSelector((state: IState) => state.diff.diffVersion);
    const { data, isError, isPending } = useAiAssist({ language: lang, text, version });

    const onUse = (text: string) => {
        close();
        dispatch(editContent({ version, lang, id, content, value: text }));
    };

    let main = null;
    if (isPending) {
        main = <CircleSpinner size={48} color="#666666" />;
    } else if (isError) {
        main = <p>Error</p>;
    } else if (data) {
        main = data.translations.map((res) => (
            <Container key={res}>
                <Trans>
                    <Nl2br text={res} />
                </Trans>
                <div>
                    <button type="button" onClick={() => onUse(res)}>
                        Use translation
                    </button>
                </div>
            </Container>
        ));
    }

    return (
        <>
            <h2>Ai Assist</h2>
            <Grid>
                <h3>English text</h3>
                <h3>Suggested translations</h3>
                <Trans>
                    <Nl2br text={text} />
                </Trans>
                <div>{main}</div>
            </Grid>
        </>
    );
}

export default React.memo(AiAssist);
