import { store } from "./state";
import type { IContentType, ILanguage } from "./types";

function getAuthHeader(): HeadersInit {
    const state = store.getState();
    const authToken = state.auth.authToken;

    if (authToken) {
        return {
            Authorization: `Basic ${authToken}`,
        };
    }
    return {};
}

async function fetchJson(url: string, settings?: RequestInit): Promise<any> {
    const headers = {
        ...settings?.headers,
        ...getAuthHeader(),
    };

    try {
        // biome-ignore lint: Use var to hoist the declaration outside the try statement
        var response = await fetch(url, { ...settings, headers });
    } catch (_err) {
        throw new Error("Network error");
    }
    if (response.status === 401) {
        throw new Error("Unauthorized");
    }
    if (!response.ok) {
        const err = new Error(`${response.statusText}`);
        throw err;
    }
    const json = await response.json();
    return json;
}

async function getJson(url: string): Promise<any> {
    return fetchJson(url);
}

async function postJson(url: string, data: any, signal?: AbortSignal): Promise<any> {
    const postSettings: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
        signal,
    };
    return fetchJson(url, postSettings);
}

export async function getVersions(): Promise<string[]> {
    return getJson("/api/versions");
}

interface IDict {
    [index: string]: string;
}

export type IContent = {
    [key in ILanguage]: IDict;
};

export interface IData {
    texts: IContent;
    docs: IContent;
    defaultContent: IContent;
}

export async function getTranslations(version: string): Promise<IData> {
    return getJson(`/api/data/${version}`);
}

interface ISaveData {
    version: string;
    data: IData;
}

export async function saveTranslations(data: ISaveData): Promise<IData> {
    return postJson("/api/save", data);
}

export async function login(username: string, password: string): Promise<string> {
    try {
        const response = await postJson("/api/login", { username, password });
        return response.token;
    } catch (error) {
        if (error instanceof Error) {
            if (error.message === "Unauthorized") {
                throw new Error("Invalid login information");
            }
        }
        throw error;
    }
}

export interface ITranslationHistory {
    version: string;
    content: string;
    createdAt: string | null;
    user: string | null;
}

export async function getTranslationHistory(
    id: string,
    language: ILanguage,
    content: IContent,
): Promise<ITranslationHistory[]> {
    return getJson(`/api/history/${id}/${language}/${content}`);
}

export interface IAiAssistArgs {
    language: ILanguage;
    text: string;
    version: string;
}

interface IAiAssistData {
    translations: string[];
}

export async function getAiAssist(params: IAiAssistArgs): Promise<IAiAssistData> {
    return postJson("/api/ai-assist", params);
}

export interface IHistoryArgs {
    id: string;
    language: ILanguage;
    content: IContentType;
}

interface IHistoryDatum {
    id: number;
    version: string;
    content: string;
    createdAt: string | null;
    user: string | null;
}

export async function getHistory(params: IHistoryArgs): Promise<IHistoryDatum[]> {
    return getJson(`/api/history/${params.id}/${params.language}/${params.content}`);
}
