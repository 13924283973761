import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Modal from "./Modal";
import { setShowLoginModal, setAuthToken } from "./state/auth";
import type { IState } from "./state";
import { login } from "./api";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
`;

const ErrorMessage = styled.div`
    color: red;
    margin-bottom: 1rem;
`;

const LoginModal: React.FC = () => {
    const dispatch = useDispatch();
    const showLoginModal = useSelector((state: IState) => state.auth.showLoginModal);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        try {
            const token = await login(username, password);
            dispatch(setAuthToken(token));
            dispatch(setShowLoginModal(false));
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unknown error occurred");
            }
        }
    };

    return (
        <Modal
            isOpen={showLoginModal}
            onRequestClose={() => dispatch(setShowLoginModal(false))}
            contentLabel="Login Modal"
        >
            <Form onSubmit={handleSubmit}>
                <h2>Login</h2>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                <button type="submit">Login</button>
            </Form>
        </Modal>
    );
};

export default LoginModal;
