import * as React from "react";

interface IProps {
    msg?: string;
}

function DisplayError({ msg }: IProps) {
    return <div>{msg ?? "ERROR"}</div>;
}

export default DisplayError;
