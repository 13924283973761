import { parse } from "messageformat-parser";
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../messageformat-parser.d.ts"/>
import * as React from "react";
import { useDebounce } from "use-debounce";

import { ContentBorder, ContentBox, ContentError } from "../stylings";
import { AI_LANGUAGES, type ILanguage } from "../types";
import EditorButtons from "./EditorButtons";
import TextDiff from "./TextDiff";
import TextPreview from "./TextPreview";
import Textarea from "./Textarea";

interface IProps {
    text: string;
    lang: ILanguage;
    id: string;
    version: string;
    resetHandler: (lang: ILanguage) => void;
    changeHandler: (event: React.FormEvent<HTMLTextAreaElement>, lang: ILanguage) => void;
    setAiModal: (lang: ILanguage) => void;
    setHistoryModal: (lang: ILanguage) => void;
}

function validate(value: string): string {
    if (!value) {
        return "";
    }
    try {
        parse(value);
    } catch (err: any) {
        return err.message;
    }
    return "";
}

type ITab = "editor" | "preview" | "diff";

function TextItem({ text, changeHandler, resetHandler, lang, setAiModal, setHistoryModal, version, id }: IProps) {
    const [debounceText] = useDebounce(text, 1000);
    const [tab, setTab] = React.useState<ITab>("editor");

    const error = React.useMemo(() => {
        return validate(debounceText);
    }, [debounceText]);

    let mainContent = null;
    if (tab === "editor") {
        mainContent = (
            <ContentBorder $pad={false}>
                <Textarea value={text} onChange={(e) => changeHandler(e, lang)} />
                {error && <ContentError>{error}</ContentError>}
            </ContentBorder>
        );
    } else if (tab === "preview") {
        mainContent = (
            <ContentBorder $pad={true}>
                <TextPreview text={text} lang={lang} />
            </ContentBorder>
        );
    } else if (tab === "diff") {
        mainContent = (
            <ContentBorder $pad={true}>
                <TextDiff version={version} lang={lang} id={id} />
            </ContentBorder>
        );
    }

    return (
        <ContentBox>
            <EditorButtons
                tab={tab}
                setTab={setTab}
                onReset={() => resetHandler(lang)}
                openAI={() => setAiModal(lang)}
                openHistory={() => setHistoryModal(lang)}
                showAI={AI_LANGUAGES.includes(lang)}
            />
            {mainContent}
        </ContentBox>
    );
}

export default React.memo(TextItem);
