import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import styled from "styled-components";

import type { IParams } from "./App";
import SaveResultBanner from "./SaveResultBanner";
import { useSaveTranslations } from "./queries/useTranslations";
import { type IDispatch, resetAllEdits, unsavedChangesSelector } from "./state";
import type { IState } from "./state";

const Button = styled.button`
    padding: 0.25rem 0.6rem;
    font-size: 1.2rem;
`;

interface IProps {
    showReset?: boolean;
}

function SaveButton({ showReset }: IProps) {
    const { version } = useParams<IParams>();
    if (!version) {
        throw new Error("Version is undefined");
    }

    const dispatch = useDispatch<IDispatch>();
    const unsavedChanges = useSelector((state: IState) => unsavedChangesSelector(state, version));
    const { mutate, isPending, isSuccess, isError, reset } = useSaveTranslations();

    const onSave = () => {
        if (!version) {
            return;
        }
        mutate({ version });
    };

    const onReset = () => {
        if (!version) {
            return;
        }
        if (!window.confirm("This will reset ALL unsaved changes.")) {
            return;
        }
        dispatch(resetAllEdits(version));
    };

    let portal = null;
    if (isSuccess || isError) {
        portal = createPortal(<SaveResultBanner success={isSuccess} onClose={() => reset()} />, document.body);
    }

    const disabled = isPending || !unsavedChanges;

    const changesText =
        unsavedChanges === 0
            ? "No unsaved changes"
            : unsavedChanges === 1
              ? "1 unsaved change"
              : `${unsavedChanges} unsaved changes`;

    return (
        <>
            <Button disabled={disabled} type="button" onClick={onSave}>
                Save
            </Button>
            {showReset && (
                <Button type="button" onClick={onReset} disabled={disabled}>
                    Reset all
                </Button>
            )}
            {isPending && (
                <>
                    <CircleSpinner size={28} color="#666666" />
                    <span>Saving</span>
                </>
            )}
            {isPending && <span>{changesText}</span>}
            {portal}
        </>
    );
}

export default SaveButton;
