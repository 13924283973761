import * as React from "react";

interface IProps {
    text: string;
}

function Nl2br({ text }: IProps) {
    if (!text) {
        return null;
    }
    const splits = text.split("\n");
    const outText = [];
    let i = 0;
    for (const split of splits) {
        i++;
        outText.push(<React.Fragment key={i}>{split}</React.Fragment>);
        i++;
        outText.push(<br key={i} />);
    }
    outText.pop();
    return <>{outText}</>;
}

export default React.memo(Nl2br);
