import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useStore } from "react-redux";
import * as API from "../api";
import { type IStore, resetAllEdits } from "../state";
import { withAuth } from "../state/withAuth";
import { type IContentType, type ILanguage, LANGUAGES, REFERENCE_LANGUAGE } from "../types";
import { queryClient } from "./queryClient";

function authGetTranslations(version: string) {
    return withAuth(() => API.getTranslations(version));
}

function select(data: API.IData): ITranslation {
    const defaultContent = invertData(data.defaultContent);
    const texts = invertData(data.texts);
    const docs = invertData(data.docs);
    return {
        defaultContent: {
            dict: defaultContent,
            ids: Object.keys(defaultContent).sort(),
        },
        texts: {
            dict: texts,
            ids: Object.keys(texts).sort(),
        },
        docs: {
            dict: docs,
            ids: Object.keys(docs).sort(),
        },
    };
}

function translationOptions(version: string) {
    return queryOptions({
        queryKey: ["translations", version],
        queryFn: () => authGetTranslations(version),
        staleTime: Number.POSITIVE_INFINITY,
        enabled: !!version,
        select,
    });
}

export async function fetchTranslations(version: string) {
    const data = await queryClient.fetchQuery(translationOptions(version));
    return select(data);
}

export function useTranslations(version: string) {
    return useQuery(translationOptions(version));
}

type IEditedItem = {
    content: IContentType;
    id: string;
    language: ILanguage;
    value: string;
};

function getEditedItems(
    data: {
        [P in IContentType]?: IPartialLangDict;
    },
): IEditedItem[] {
    return Object.entries(data).flatMap(([content, dict]) =>
        Object.entries(dict).flatMap(([id, langEdits]) =>
            Object.entries(langEdits).map(([lang, value]) => ({
                content: content as IContentType,
                id,
                language: lang as ILanguage,
                value,
            })),
        ),
    );
}

export function useSaveTranslations() {
    const queryClient = useQueryClient();
    const store = useStore.withTypes<IStore>()();

    return useMutation({
        mutationFn: async ({ version }: { version: string }) => {
            const state = store.getState();
            const data = state.edits[version].data;
            const editedItems = getEditedItems(data);

            const response = await withAuth(() =>
                API.saveTranslations({
                    data: revertData(data),
                    version,
                }),
            );
            return { response, version, editedItems };
        },
        onSuccess: ({ response, version, editedItems }) => {
            queryClient.setQueryData(["translations", version], response);

            for (const { content, id, language, value } of editedItems) {
                queryClient.invalidateQueries({
                    queryKey: ["history", id, language, content],
                });
                if (language === REFERENCE_LANGUAGE) {
                    queryClient.invalidateQueries({
                        queryKey: ["ai-assist", language, value],
                    });
                }
            }

            store.dispatch(resetAllEdits(version));
        },
    });
}

export type ILangData = {
    [key in ILanguage]: string;
};

interface ILangDict {
    [index: string]: ILangData;
}

export interface IPartialLangDict {
    [index: string]: Partial<ILangData>;
}

export interface ITranslation {
    texts: {
        ids: string[];
        dict: ILangDict;
    };
    docs: {
        ids: string[];
        dict: ILangDict;
    };
    defaultContent: {
        ids: string[];
        dict: ILangDict;
    };
}

function revertData(
    data: {
        [P in IContentType]?: IPartialLangDict;
    },
): API.IData {
    const out: API.IData = {
        texts: LANGUAGES.reduce((obj, lang) => {
            obj[lang] = {};
            return obj;
        }, {} as API.IContent),
        docs: LANGUAGES.reduce((obj, lang) => {
            obj[lang] = {};
            return obj;
        }, {} as API.IContent),
        defaultContent: LANGUAGES.reduce((obj, lang) => {
            obj[lang] = {};
            return obj;
        }, {} as API.IContent),
    };
    for (const [content, dict] of Object.entries(data) as [IContentType, IPartialLangDict][]) {
        for (const [id, item] of Object.entries(dict)) {
            for (const [lang, value] of Object.entries(item) as [ILanguage, string][]) {
                out[content][lang][id] = value;
            }
        }
    }
    return out;
}

function invertData(data: API.IContent): ILangDict {
    const out: ILangDict = {};
    for (const id of Object.keys(data[REFERENCE_LANGUAGE])) {
        out[id] = LANGUAGES.reduce((obj, lang) => {
            obj[lang] = data[lang][id];
            return obj;
        }, {} as ILangData);
    }
    return out;
}
