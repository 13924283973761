import { createSelector } from "@reduxjs/toolkit";

import type { ITranslation } from "../queries/useTranslations";
import { type ILanguage, LANGUAGES, PAGE_SIZE } from "../types";
import { onlyUnique } from "../utils";
import type { IState } from "./index";

const editsSelector = (state: IState, version: string) =>
    state.edits[version] ?? {
        data: {},
    };

export const textModulesSelector = createSelector(
    (_, __, translations: ITranslation) => translations,
    (translations) => {
        const modules = Object.keys(translations.texts.dict).map((id) => id.split(".")[0]);
        return modules.filter(onlyUnique);
    },
);

export const docsModulesSelector = createSelector(
    (_, __, translations: ITranslation) => translations,
    (translations) => {
        const modules = Object.keys(translations.docs.dict).map((id) => id.split(".")[0]);
        return modules.filter(onlyUnique);
    },
);

export const defaultContentModulesSelector = createSelector(
    (_, __, translations: ITranslation) => translations,
    (translations) => {
        const modules = Object.keys(translations.defaultContent.dict).map((id) => id.split(".")[0]);
        return modules.filter(onlyUnique);
    },
);

const textsIdsSelector = createSelector(
    (_state: IState, _version: string, translations: ITranslation) => translations,
    (state: IState, version: string, _translations: ITranslation) => state.filters[version]?.searchResults,
    textModulesSelector,
    (state: IState, _version: string) => state.modules,
    (translation, searchResults, modules, disabledModules) => {
        const ids = searchResults?.texts ?? translation.texts.ids;
        const filteredModules = modules.filter((mod) => !disabledModules[mod]);
        return ids.filter((id) => filteredModules.includes(id.split(".")[0]));
    },
);

const docsIdsSelector = createSelector(
    (_state: IState, _version: string, translations: ITranslation) => translations,
    (state: IState, version: string, _translations: ITranslation) => state.filters[version]?.searchResults,
    docsModulesSelector,
    (state: IState, _version: string) => state.modules,
    (translation, searchResults, modules, disabledModules) => {
        const ids = searchResults?.docs ?? translation.docs.ids;
        const filteredModules = modules.filter((mod) => !disabledModules[mod]);
        return ids.filter((id) => filteredModules.includes(id.split(".")[0]));
    },
);

const defaultContentIdsSelector = createSelector(
    (_state: IState, _version: string, translations: ITranslation) => translations,
    (state: IState, version: string) => state.filters[version]?.searchResults,
    defaultContentModulesSelector,
    (state: IState, _version: string) => state.modules,
    (translation, searchResults, modules, disabledModules) => {
        const ids = searchResults?.defaultContent ?? translation.defaultContent.ids;
        const filteredModules = modules.filter((mod) => !disabledModules[mod]);
        return ids.filter((id) => filteredModules.includes(id.split(".")[0]));
    },
);

export const textCountsSelector = createSelector(
    textsIdsSelector,
    (_state: IState, _version: string, translations: ITranslation) => translations,
    (filterIds, translation) => {
        return [filterIds.length, translation.texts.ids.length];
    },
);

export const docCountsSelector = createSelector(
    docsIdsSelector,
    (_state: IState, _version: string, translations: ITranslation) => translations,
    (filterIds, translation) => {
        return [filterIds.length, translation.docs.ids.length];
    },
);

export const defaultContentCountsSelector = createSelector(
    defaultContentIdsSelector,
    (_state: IState, _version: string, translations: ITranslation) => translations,
    (filterIds, translation) => {
        return [filterIds.length, translation.defaultContent.ids.length];
    },
);

export const textsPageSelector = createSelector(
    textsIdsSelector,
    (_state: IState, _version: string, _translations: ITranslation, page: number) => page,
    (ids, page) => {
        return {
            pageCount: Math.ceil(ids.length / PAGE_SIZE),
            ids: ids.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
        };
    },
);

export const docsPageSelector = createSelector(
    docsIdsSelector,
    (_state: IState, _version: string, _translations: ITranslation, page: number) => page,
    (ids, page) => {
        return {
            pageCount: Math.ceil(ids.length / PAGE_SIZE),
            ids: ids.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
        };
    },
);

export const defaultContentPageSelector = createSelector(
    defaultContentIdsSelector,
    (_state: IState, _version: string, _translations: ITranslation, page: number) => page,
    (ids, page) => {
        return {
            pageCount: Math.ceil(ids.length / PAGE_SIZE),
            ids: ids.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
        };
    },
);

const editTextsSelector = createSelector(
    (state: IState, version: string, _id: string) => editsSelector(state, version).data,
    (_state: IState, _version: string, id: string) => id,
    (edits, id) => {
        if (!edits.texts) {
            return;
        }
        if (!edits.texts[id]) {
            return;
        }
        return edits.texts[id];
    },
);

const editDocsSelector = createSelector(
    (state: IState, version: string, _id: string) => editsSelector(state, version).data,
    (_state: IState, _version: string, id: string) => id,
    (edits, id) => {
        if (!edits.docs) {
            return;
        }
        if (!edits.docs[id]) {
            return;
        }
        return edits.docs[id];
    },
);

const editDefaultContentSelector = createSelector(
    (state: IState, version: string, _id: string) => editsSelector(state, version).data,
    (_state: IState, _version: string, id: string) => id,
    (edits, id) => {
        if (!edits.defaultContent) {
            return;
        }
        if (!edits.defaultContent[id]) {
            return;
        }
        return edits.defaultContent[id];
    },
);

export const textsItemSelector = createSelector(
    (_state: IState, _version: string, _id: string, translations: ITranslation) => translations,
    (state: IState, version: string, id: string) => editTextsSelector(state, version, id),
    (_state: IState, _version: string, id: string) => id,
    (translation, edits, id) => {
        return LANGUAGES.reduce(
            (obj, lang) => {
                obj[lang] = edits?.[lang] ?? translation.texts.dict[id]?.[lang];
                return obj;
            },
            {} as { [key in ILanguage]: string },
        );
    },
);

export const docsItemSelector = createSelector(
    (_state: IState, _version: string, _id: string, translations: ITranslation) => translations,
    (state: IState, version: string, id: string) => editDocsSelector(state, version, id),
    (_state: IState, _version: string, id: string) => id,
    (translation, edits, id) => {
        return LANGUAGES.reduce(
            (obj, lang) => {
                obj[lang] = edits?.[lang] ?? translation.docs.dict[id]?.[lang];
                return obj;
            },
            {} as { [key in ILanguage]: string },
        );
    },
);
export const defaultContentItemSelector = createSelector(
    (_state: IState, _version: string, _id: string, translations: ITranslation) => translations,
    (state: IState, version: string, id: string) => editDefaultContentSelector(state, version, id),
    (_state: IState, _version: string, id: string) => id,
    (translation, edits, id) => {
        return LANGUAGES.reduce(
            (obj, lang) => {
                obj[lang] = edits?.[lang] ?? translation.defaultContent.dict[id]?.[lang];
                return obj;
            },
            {} as { [key in ILanguage]: string },
        );
    },
);

export const unsavedChangesSelector = createSelector(
    (state: IState) => state.edits,
    (_state: IState, version: string) => version,
    (edits, version) => {
        if (!edits[version]) {
            return 0;
        }
        const numEdits = Object.values(edits[version].data)
            .map((cat) => Object.keys(cat).length)
            .reduce((acc, val) => acc + val, 0);
        return numEdits;
    },
);

export const anyUnsavedChangesSelector = createSelector(
    (state: IState) => state.edits,
    (edits) => {
        for (const version of Object.values(edits)) {
            if (Object.keys(version.data).length > 0) {
                return true;
            }
        }
        return false;
    },
);

export const hasSearchResults = createSelector(
    (state: IState, version: string) => state.filters[version],
    (filter) => {
        return !!filter?.searchResults;
    },
);
