import * as React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CircleSpinner } from "react-spinners-kit";

import IdFilter from "./IdFilter";
import SearchFilter from "./SearchFilter";
import ChangeFilter from "./ChangeFilter";
import { type IState, type IDispatch, search, clearSearch } from "../state";

const Row = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

function Search() {
    const dispatch = useDispatch<IDispatch>();
    const { version } = useParams();
    const navigate = useNavigate();

    if (!version) {
        throw new Error("Version is not defined");
    }

    const isSearching = useSelector((state: IState) => state.filters[version]?.isSearching ?? false);

    const onSearch = () => {
        dispatch(search(version));
        navigate(`/${version}/texts`);
    };

    const onClear = () => {
        dispatch(clearSearch(version));
        navigate(`/${version}/texts`);
    };

    return (
        <>
            <fieldset>
                <legend>Search</legend>
                <IdFilter version={version} />
                <SearchFilter version={version} />
                <ChangeFilter version={version} />
                <Row>
                    <button type="button" onClick={onSearch} disabled={isSearching}>
                        Search
                    </button>
                    <button type="button" onClick={onClear} disabled={isSearching}>
                        Clear
                    </button>
                    {isSearching && (
                        <>
                            <CircleSpinner size={24} color="#666666" />
                            <span>Searching</span>
                        </>
                    )}
                </Row>
            </fieldset>
        </>
    );
}

export default Search;
